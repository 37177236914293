import classNames from 'clsx';
import type { SlideShowSkin } from '@wix/thunderbolt-components-native';
import * as React from 'react';
import type { IStateBoxMapperProps } from '../../StateBox/StateBox.types';
import type { NavigationButtonsProps } from '../SlideShowContainer.types';
import { translations as translationKeys, TestIds } from '../constants';
import styles from './style/SlideShowContainer.scss';
import thinArrowSVG from './assets/thinArrow.svg';
import longArrowSVG from './assets/longArrow.svg';
import squareButtonSVG from './assets/squareButton.svg';

type SlideShowContainerSkin = SlideShowSkin | IStateBoxMapperProps['skin'];
const skinToArrowSVGs: Record<SlideShowContainerSkin, React.FC> = {
  thinArrowsSkin: thinArrowSVG,
  thinArrowsLargeSelectedCircleSkin: thinArrowSVG,
  longArrowsLargeSelectedCircleSkin: longArrowSVG,
  squareButtonsSkin: squareButtonSVG,
  StateBoxSkin: () => null,
};

const NavigationButtons: React.FC<NavigationButtonsProps> = ({
  skin,
  moveToNextSlide,
  moveToPrevSlide,
  translations,
}) => {
  const NavButtonSVG = skinToArrowSVGs[skin];

  return (
    <React.Fragment>
      <button
        data-testid={TestIds.prevButton}
        aria-label={
          translations.prevButtonAriaLabel ||
          translationKeys.PREV_BTN_ARIA_LABEL_DEFAULT
        }
        onClick={moveToPrevSlide}
        className={classNames(styles.navBtn, styles.prevBtn)}
      >
        <NavButtonSVG />
      </button>
      <button
        data-testid={TestIds.nextButton}
        aria-label={
          translations.nextButtonAriaLabel ||
          translationKeys.NEXT_BTN_ARIA_LABEL_DEFAULT
        }
        onClick={moveToNextSlide}
        className={classNames(styles.navBtn, styles.nextBtn)}
      >
        <NavButtonSVG />
      </button>
    </React.Fragment>
  );
};

export default NavigationButtons;
